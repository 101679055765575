import {useCallback, useMemo, useState} from 'react';

import {type TMessengerKeys, MESSENGER_LABEL} from './const';

interface UseHandleSubmitDataReturn<T extends TMessengerKeys> {
	resetMessengers: () => void;
	messengerValues: string | null;
	messengers: T[];
	setMessengers: (v: T[]) => void;
}
export function useHandleSubmitData<T extends TMessengerKeys>(): UseHandleSubmitDataReturn<T> {
	const [messengers, setMessengers] = useState<T[]>([]);

	const messengerLabels = useMemo(() => {
		return messengers.reduce((acc, field) => {
			if (MESSENGER_LABEL[field]) {
				acc.push(MESSENGER_LABEL[field]);
			}
			return acc;
		}, []);
	}, [messengers]);

	const messengerValues = useMemo(() => {
		if (messengerLabels.length) {
			return `Как связаться: ${messengerLabels.join(', ')}`;
		}

		return null;
	}, [messengerLabels]);

	const resetMessengers = useCallback(() => {
		setMessengers([]);
	}, []);

	return {
		resetMessengers,
		messengerValues,
		setMessengers,
		messengers,
	};
}
