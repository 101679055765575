import cn from 'classnames';
import {useCallback} from 'react';

import {type MESSENGERS, data} from './const';
import styles from './MessengerBlock.module.css';

interface MessengerBlockProps {
	onClick: (v: MESSENGERS[]) => void;
	currentValues?: MESSENGERS[];
	className?: string;
}
export function MessengerBlock({onClick, currentValues = [], className}: MessengerBlockProps) {
	const handlerClick = useCallback(
		(v) => {
			const set = new Set(currentValues);
			if (set.has(v)) {
				set.delete(v);
			} else {
				set.add(v);
			}
			onClick([...set]);
		},
		[currentValues, onClick],
	);

	return (
		<div className={cn(styles.root, className)}>
			<div className={styles.title}>Как с вами связаться?</div>
			<div className={styles.list}>
				{data.map(({id, label, Logo, value}) => {
					return (
						<div
							className={cn(styles.item, {
								[styles.selected]: currentValues.includes(value),
							})}
							key={id}
							onClick={() => handlerClick(value)}
						>
							<Logo className={styles.logo} />
							<div className={styles.label}>{label}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
