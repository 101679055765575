import classNames from 'classnames';
import type {UseFormRegisterReturn} from 'react-hook-form';

import {type InputProps, Input} from '@/components/base/input/Input';
import {type AutocompleteType, INPUT_STYLES} from '@/components/base/input/types';

import styles from './RequiredInput.module.css';

interface RequiredInputProps extends InputProps {
	autocomplete: AutocompleteType;
	controlClassName: string;
	error: string;
	id: string;
	placeholder?: string;
	registerProps?: UseFormRegisterReturn;
	inputStyle?: INPUT_STYLES;
	className?: string;
}

export function RequiredInput({
	autocomplete,
	controlClassName,
	error,
	id,
	placeholder,
	registerProps,
	inputStyle = INPUT_STYLES.DEFAULT,
	className,
	...otherProps
}: RequiredInputProps) {
	return (
		<div
			className={classNames(styles.requiredWrapper, className, {
				[styles.appearanceBlue]: inputStyle === INPUT_STYLES.BLUE,
			})}
		>
			<Input
				id={id}
				autocomplete={autocomplete}
				className={classNames(controlClassName, styles.requiredInput)}
				{...(placeholder ? {placeholder} : {})}
				invalid={!!error}
				inputStyle={inputStyle}
				{...otherProps}
				{...registerProps}
			/>
			{error && <div className={styles.fieldError}>{error}</div>}
			<div className={styles.required}>*</div>
		</div>
	);
}
