import type {ValidateResult} from 'react-hook-form';

import {LINKS} from '@/constants';
import {validateEmail} from '@/utilites/validate-email/validate-email';
import {validatePhone} from '@/utilites/validate-phone/validate-phone';

export const registerPhone = {
	required: true,
	validate: (value): ValidateResult => {
		if (validatePhone(value)) {
			return true;
		}
		return 'Некорректный номер телефона';
	},
};
export const registerEmail = {
	required: true,
	validate: (value): ValidateResult => {
		if (validateEmail(value)) {
			return true;
		}
		return 'Некорректный адрес электронной почты';
	},
};

export const getRecaptchaDisclaimer = (): JSX.Element => {
	return (
		<>
			This site is&nbsp;protected by&nbsp;reCAPTCHA and the Google&nbsp;
			<a href={LINKS.bizGooglePrivacy} rel="noopener noreferrer" target="_blank">
				Privacy Policy
			</a>
			&nbsp;and&nbsp;
			<a href={LINKS.bizGoogleTermsOfService} rel="noopener noreferrer" target="_blank">
				Terms of&nbsp;Service
			</a>{' '}
			apply.
		</>
	);
};

export const getAgreement = (): JSX.Element => {
	return (
		<>
			Я принимаю условия&nbsp;
			<a href={LINKS.saasDocsContentAgreement} rel="noopener noreferrer" target="_blank">
				Соглашения об&nbsp;информационном взаимодействии
			</a>
		</>
	);
};
