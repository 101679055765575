import type {VFC} from 'react';

import PhoneLogo from './assets/phone.svg';
import TelegramLogo from './assets/telegram.svg';
import WhatsappLogo from './assets/whatsapp.svg';

export enum MESSENGERS {
	WHATSAPP = 'whatsapp',
	TELEGRAM = 'telegram',
	PHONE = 'phone',
}

export const MESSENGER_LABEL = {
	[MESSENGERS.PHONE]: 'Звонок',
	[MESSENGERS.TELEGRAM]: 'Telegram',
	[MESSENGERS.WHATSAPP]: 'WhatsApp',
};
export type TMessengerKeys = keyof typeof MESSENGER_LABEL;

export type ItemProps = {
	id: number;
	label: string;
	Logo: VFC<React.SVGProps<SVGSVGElement>>;
	value: MESSENGERS;
};

export const data: ItemProps[] = [
	{
		id: 1,
		label: MESSENGER_LABEL[MESSENGERS.WHATSAPP],
		Logo: WhatsappLogo,
		value: MESSENGERS.WHATSAPP,
	},
	{
		id: 2,
		label: MESSENGER_LABEL[MESSENGERS.TELEGRAM],
		Logo: TelegramLogo,
		value: MESSENGERS.TELEGRAM,
	},
	{
		id: 3,
		label: MESSENGER_LABEL[MESSENGERS.PHONE],
		Logo: PhoneLogo,
		value: MESSENGERS.PHONE,
	},
];
